import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_high-five/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {Profile} from '../pages/dashboard/Profile'
import {Contact} from '../pages/dashboard/Contact'
import {CompanyProfile} from '../pages/dashboard/CompanyProfile'
import {ChangePassword} from '../pages/dashboard/ChangePassword'



import {getCSSVariableValue} from '../../_high-five/assets/ts/_utils'
import {WithChildren} from '../../_high-five/helpers'
import {Policies} from '../pages/dashboard/management/policies/Policies'
import {PoiciesDetails} from '../pages/dashboard/management/policies/PoiciesDetails'
import {PoiciesDetailsView} from '../pages/dashboard/management/policies/PoiciesDetailsView'
import {PoiciesDetailsEdit} from '../pages/dashboard/management/policies/PoiciesDetailsEdit'



import Employees from '../pages/dashboard/management/employees/Employees'
import {PaymentSettings} from '../pages/dashboard/management/payment/PaymentSettings'
import {Contacts} from '../pages/dashboard/management/contacts/Contacts'
import {Terms} from '../pages/dashboard/Terms'


import {TaxSettings} from '../pages/dashboard/management/tax/TaxSettings'
import {Account} from '../pages/dashboard/account/Account'
import {Schedule} from '../pages/dashboard/schedule/Schedule'
 import PublicEvent from "../pages/dashboard/schedule/PublicEvent"

import Booking from '../pages/dashboard/schedule/Booking'
import Session from '../pages/dashboard/schedule/Session'


import {WeeklySchedule} from '../pages/dashboard/schedule/WeeklySchedule'


import {Members} from '../pages/dashboard/members/Members'
import {MembersRequest} from '../pages/dashboard/members/MembersRequest'
import {MembersExpired} from '../pages/dashboard/members/MembersExpired'
import {MembersCategory} from '../pages/dashboard/members/MembersCategory'
import {NewMember} from '../pages/dashboard/members/NewMember'
import {CreatMembers} from '../pages/dashboard/members/CreatMembers'
import {EditMembers} from '../pages/dashboard/members/EditMembers'


import Customers from '../pages/dashboard/customers/Customers'
import {CustomersWalk} from '../pages/dashboard/customers/CustomersWalk'
import {Reservation} from '../pages/dashboard/reservation/Reservation'
import {FieldsMangement} from '../pages/dashboard/fields/FieldsMangement'
import {Branches} from '../pages/dashboard/fields/Branches'
import {AddNewBranch} from '../pages/dashboard/fields/AddNewBranch'



import {SessionMangement} from '../pages/dashboard/fields/SessionMangement'
import {FieldDetails} from '../pages/dashboard/fields/FieldDetails'
import {Comments} from '../pages/dashboard/fields/Comments'

import {AssigendEmployee} from '../pages/dashboard/fields/AssigendEmployee'

import BookingMehodology from '../pages/dashboard/fields/BookingMehodology'
import AddNewField from '../pages/dashboard/fields/AddNewField'

import {PublicDetailsPast} from '../pages/dashboard/reservation/PublicDetailsPast'
import {BookingDetails} from '../pages/dashboard/reservation/BookingDetails'


const PrivateRoutes = () => {

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* dashboard */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='dashboard/Profile' element={<Profile />} />
        <Route path='dashboard/CompanyProfile' element={<CompanyProfile />} />
        <Route path='dashboard/Contact' element={<Contact />} />
        <Route path='dashboard/Terms' element={<Terms />} />
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='dashboard/ChangePassword' element={<ChangePassword />} />


        {/* account */}

        <Route path='dashboard/account/Account' element={<Account />} />
        {/* schedule */}

        <Route path='dashboard/schedule/Schedule' element={<Schedule />} />
        <Route path='dashboard/schedule/PublicEvent' element={<PublicEvent />} />
        <Route path='dashboard/schedule/Booking' element={<Booking />} />
        <Route path='dashboard/schedule/Session' element={<Session />} />

        
        <Route path='dashboard/schedule/WeeklySchedule' element={<WeeklySchedule />} />

         {/* start reservation pages */}

        <Route path='dashboard/reservation/Reservation' element={<Reservation />} />
        <Route path='dashboard/reservation/PublicDetailsPast' element={<PublicDetailsPast />} />
        <Route path='dashboard/reservation/BookingDetails' element={<BookingDetails />} />
        {/* start fields pages */}

        <Route path='dashboard/fields/FieldsMangement' element={<FieldsMangement />} />

        <Route path='dashboard/fields/SessionMangement' element={<SessionMangement />} />
        <Route path='dashboard/fields/FieldDetails' element={<FieldDetails />} />
        <Route path='dashboard/fields/Comments' element={<Comments />} />
        <Route path='dashboard/fields/AssigendEmployee' element={<AssigendEmployee />} />
        <Route path='dashboard/fields/BookingMehodology' element={<BookingMehodology />} />
        <Route path='dashboard/fields/AddNewField' element={<AddNewField />} />
        <Route path='dashboard/fields/Branches' element={<Branches />} />
        <Route path='dashboard/fields/AddNewBranch' element={<AddNewBranch />} />

        
        

        {/* start Members pages */}

        <Route path='dashboard/members/Members' element={<Members />} />
        <Route path='dashboard/members/CreatMembers' element={<CreatMembers />} />
        <Route path='dashboard/members/EditMembers' element={<EditMembers />} />

        
        <Route path='dashboard/members/MembersRequest' element={<MembersRequest />} />
        <Route path='dashboard/members/MembersCategory' element={<MembersCategory />} />
        <Route path='dashboard/members/NewMember' element={<NewMember />} />
        <Route path='dashboard/members/CreatMembers' element={<CreatMembers />} />
        <Route path='dashboard/members/MembersExpired' element={<MembersExpired />} />



        {/* start mangement pages */}
        <Route path='dashboard/management/policies/Policies' element={<Policies />} />
        <Route path='dashboard/management/policies/PoiciesDetails' element={<PoiciesDetails />} />
        <Route path='dashboard/management/policies/PoiciesDetailsView' element={<PoiciesDetailsView />} />
        <Route path='dashboard/management/policies/PoiciesDetailsEdit' element={<PoiciesDetailsEdit />} />

        
        
        <Route path='dashboard/management/employees/Employees' element={<Employees />} />
        <Route path='dashboard/management/payment/PaymentSettings' element={<PaymentSettings />} />
        <Route path='dashboard/management/contacts/Contacts' element={<Contacts />} />
        <Route path='dashboard/management/tax/TaxSettings' element={<TaxSettings />} />
        {/* start customers */}
        <Route path='dashboard/customers/Customers' element={<Customers />} />
        <Route path='dashboard/customers/CustomersWalk' element={<CustomersWalk />} />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}



export {PrivateRoutes}