import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router-dom'
import {Link} from 'react-router-dom'

import Breadcrumb from '../../../../_high-five/partials/widgets/bread_crumb/Breadcrumb'
import ImageUpload2 from './ImageUpload2'
import SingleImageUpload from './SingleImageUpload'
import apiService from './apiService'
import LocationPicker from '../../../../services/LocationPicker'
import { useNavigate } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';
import { getBranchData } from './apiService'
interface branchData {
  id: number
  name: string
  description: string | null
  state: string
  city: string
  latitude: string
  longitude: string
  address: string | null
  created_at: string
  not_approved: string | null
  approved: string | null
  pending: string | null
}
const AddNewField: FC = () => {
  const navigate = useNavigate()

  const intl = useIntl()
  const location = useLocation()
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [gender, setGender] = useState('Male')
  const [activityCategoryId, setActivityCategoryId] = useState('')
  const [activityId, setActivityId] = useState('')
  const [floorTypeId, setFloorTypeId] = useState('')
  const [capacity, setCapacity] = useState<string>('')
  const [length, setLength] = useState('')
  const [width, setWidth] = useState('')
  const [cost, setCost] = useState('')
  const [type, setType] = useState('')
  const [branchId, setBranchId] = useState('')
  const [images, setImages] = useState<File[]>([])
  const [singleImage, setSingleImage] = useState<File | null>(null)
  // const [services, setServices] = useState<string>('')
  const [services, setServices] = useState<string[]>([]);

  const [conflictedFields, setConflictedFields] = useState<string[]>([])
  const [partFrom, setPartFrom] = useState<string>('')

  const [errors, setErrors] = useState<{[key: string]: string}>({})
  const [typesOptions, setTypesOptions] = useState<any[]>([])
  const [capacities, setCapacities] = useState<any[]>([])
  const [servicesOptions, setServicesOptions] = useState<any[]>([])
  const [activityCategories, setActivityCategories] = useState<any[]>([])
  const [activities, setActivities] = useState<any[]>([])
  const [mainFields, setMainFields] = useState<any[]>([])
  const [branches, setBranches] = useState<any[]>([])

  // Add states for states and cities
  const [states, setStates] = useState<any[]>([])
  const [cities, setCities] = useState<any[]>([])
  const [stateId, setStateId] = useState('')
  const [cityId, setCityId] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')

  const [latitude, setLatitude] = useState('30.033333')
  const [longitude, setLongitude] = useState('31.233334')
  const queryParams = new URLSearchParams(location.search)
  const branchQueryParam = queryParams.get('branch')
  // const [branchData, setBranchData] = useState(null)
  const [branchData, setBranchData] = useState<branchData | null>(null);

  useEffect(() => {
    const fetchActivityCategories = async () => {
      try {
        const data = await apiService.getActivityCategories()
        setActivityCategories(data)
        setTypesOptions([])
        setCapacities([])
        setServicesOptions([])
      } catch (error) {
        console.error('Error fetching activity categories:', error)
      }
    }

    fetchActivityCategories()
  }, [])

  useEffect(() => {
    if (branchQueryParam) {
      setBranchId(branchQueryParam)
      handleBranchChange(branchQueryParam)
    }
  }, [branchQueryParam])

  useEffect(() => {
    if (activityCategoryId) {
      const fetchActivities = async () => {
        try {
          const data = await apiService.getActivitiesByCategory(activityCategoryId)
          setActivities(data)
          setTypesOptions([])
        setCapacities([])
        setServicesOptions([])
        } catch (error) {
          console.error('Error fetching activities:', error)
        }
      }

      fetchActivities()
    }
  }, [activityCategoryId])

  useEffect(() => {
    if (activityId) {
      const fetchOptions = async () => {
        try {
          const data = await apiService.getActivityOptions(activityId)
          setTypesOptions(data.types)
          setCapacities(data.capacities)
          setServicesOptions(data.services)
          
        } catch (error) {
          console.error('Error fetching options:', error)
        }
      }

      fetchOptions()
    }
  }, [activityId])

  useEffect(() => {
    const fetchMainFieldsAndBranches = async () => {
      try {
        const [mainFieldsData, branchesData] = await Promise.all([
          apiService.getMainFields(),
          apiService.getBranches(),
        ])
        setMainFields(mainFieldsData)
        setBranches(branchesData)
      } catch (error) {
        console.error('Error fetching main fields or branches:', error)
      }
    }

    fetchMainFieldsAndBranches()
  }, [])

  useEffect(() => {
    // Fetch states based on the selected country (hardcoded 'egypt' here)
    const fetchStates = async () => {
      try {
        const statesData = await apiService.getStates('EG')
        setStates(statesData)
        if (statesData.length > 0) {
          setStateId(statesData[0].id)
        }
      } catch (error) {
        console.error('Error fetching states:', error)
      }
    }

    fetchStates()
  }, [])

  useEffect(() => {
    if (stateId) {
      // Fetch cities based on the selected state
      const fetchCities = async () => {
        try {
          const citiesData = await apiService.getCities(stateId)
          setCities(citiesData)
          if (citiesData.length > 0) {
            setCityId(citiesData[0].id)
          }
        } catch (error) {
          console.error('Error fetching cities:', error)
        }
      }

      fetchCities()
    }
  }, [stateId])
  useEffect(() => {
    if (type === 'main') {
      const fetchBranchData = async () => {
        try {
          const data = await getBranchData(branchId) // Assuming 3 is the branch_id
          setBranchData(data.data)
        } catch (error) {
          console.error('Error fetching branch data:', error)
        }
      }
      fetchBranchData()
    }
  }, [type])
  const handleBranchChange = async (branchId: string) => {
    setBranchId(branchId)
    try {
      const branchData = await apiService.getAddNewBranch('2')
      setCity(branchData.city_id)
      setState(branchData.state_id)
      setLatitude(branchData.lat)
      setLongitude(branchData.long)
    } catch (error) {
      console.error('Error fetching branch details:', error)
    }
  }
  const handleActivityCategoryId = async (category : string) => {
    setActivityCategoryId(category) 
    setActivities([])
    setActivityId('')
    setServices([])
    setServicesOptions([])


  }
  const handleLocationChange = () => {
    console.log('Location changed')
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    const formData = new FormData()
    formData.append('name', name)
    formData.append('description', description)
    formData.append('gender', gender)
    formData.append('activity_category_id', activityCategoryId)
    formData.append('activity_id', activityId)
    formData.append('floor_type_id', floorTypeId)
    formData.append('capacity', capacity)
    console.log(capacity, 'capacity')
    formData.append('length', "20")
    formData.append('width', "15")
    formData.append('cost', "20")
    formData.append('type', type)
    if (type === 'main') {
      formData.append('branch_id', branchId)
      formData.append('city', city)
      formData.append('state', state)
      formData.append('latitude', "30.033333")
      formData.append('longitude', '31.233334')

    }else {
      formData.append('part_from_unit', partFrom)

    }

    if (singleImage) {
      formData.append('image', singleImage)
    }

    images.forEach((image, index) => {
      formData.append(`images[${index}]`, image)
    })

    // services && formData.append('service', services)
    services.forEach((service, index) => {
      formData.append(`services[${index}]`, service);
    });
    conflictedFields.forEach((field, index) => {
      formData.append(`conflicted_fields[${index}]`, field)
    })

    try {
      const createdField = await apiService.createField(formData)
      
      toast.success('Unit added successfully!');
      navigate(-1);

      // setTimeout(() => {
      //   navigate(-1);
      
      // }, 1000);
    } catch (error) {
      console.error('Error creating field:', error)
    }
  }

  return (
    <>
      <div className='pt-5'>
        <h1>Add New Unit </h1>
        <Breadcrumb secondItem='Page' />
        <div className='card p-5'>
          <form onSubmit={handleSubmit}>
            <div className='row'>
              <div className='col-lg-6 mb-5'>
                <h3 className='mb-10'>General Information:</h3>
                <div className='mb-5'>
                  <label className='form-label'>Field Name <span className='text-danger'>*</span></label>
                  <input
                    type='text'
                    className='form-control'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className='mb-5'>
                  <label className='form-label'>Description <span className='text-danger'>*</span></label>
                  <textarea
                    className='form-control'
                    rows={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                </div>
                <div className='mb-5'>
                  <label className='form-label'>Gender <span className='text-danger'>*</span></label>
                  <div className='toolbar'>
                    <div className='form-check form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='gender'
                        value='Male'
                        checked={gender === 'Male'}
                        onChange={() => setGender('Male')}
                      />
                      <label className='form-check-label'>Male</label>
                    </div>
                    <div className='form-check form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='gender'
                        value='Female'
                        checked={gender === 'Female'}
                        onChange={() => setGender('Female')}
                      />
                      <label className='form-check-label'>Female</label>
                    </div>
                    <div className='form-check form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='gender'
                        value='Both'
                        // checked={gender === 'Both'}
                        onChange={() => setGender('Both')}
                      />
                      <label className='form-check-label'>Male & Female</label>
                    </div>
                  </div>
                </div>
                <div className='mb-5'>
                  <label className='form-label'>Activity Category <span className='text-danger'>*</span></label>
                  <div className='toolbar'>
                    {activityCategories.map((category) => (
                      <div
                        key={category.id}
                        className='form-check form-check-custom form-check-solid '
                      >
                        <input
                          className='form-check-input'
                          type='radio'
                          name='activityCategory'
                          value={category.id}
                          checked={activityCategoryId === category.id}
                          // onChange={() => setActivityCategoryId(category.id)}
                          onChange={() => handleActivityCategoryId(category.id)}
                        />
                        <label className='form-check-label'>{category.name}</label>
                      </div>
                    ))}
                  </div>
                </div>
                {activities.length > 0 && (
                  <div className='mb-5'>
                    <label className='form-label'>Activity <span className='text-danger'>*</span></label>
                    <div className='toolbar'>
                      {activities.map((activity) => (
                        <div
                          key={activity.id}
                          className='form-check form-check-custom form-check-solid '
                        >
                          <input
                            className='form-check-input'
                            type='radio'
                            name='activity'
                            value={activity.id}
                            checked={activityId === activity.id}
                            onChange={() => setActivityId(activity.id)}
                          />
                          <label className='form-check-label'>{activity.name}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {typesOptions.length > 0 && (
                  <div className='mb-5'>
                    <label className='form-label'> type </label>
                    <div className='toolbar'>
                      {typesOptions.map((typesOption) => (
                        <div
                          key={typesOption.id}
                          className='form-check form-check-custom form-check-solid '
                        >
                          <input
                            className='form-check-input'
                            type='radio'
                            name='floor_type'
                            value={typesOption.id}
                            // checked={floorTypeId === typesOption.id}
                            onChange={() => setFloorTypeId(typesOption.id)}
                          />
                          <label className='form-check-label'>{typesOption.name}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                <div>
                {capacities.length > 0 && (
                  <div className='mb-5'>
                    <label className='form-label'>Capacity </label>
                    <div className='toolbar'>
                      {capacities.map((capacity) => (
                        <div
                          key={capacity.id}
                          className='form-check form-check-custom form-check-solid '
                        >
                          <input
                            className='form-check-input'
                            type='radio'
                            name='capacity'
                            value={capacity.name}
                            // checked={capacity == capacity.name}
                            onChange={() => setCapacity(capacity.name)}
                          />
                          <label className='form-check-label'>{capacity.name}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                  
                </div>

                <div className='mb-5 d-none'>
                  <label className='form-label'>Length</label>
                  <input
                    type='text'
                    className='form-control'
                    value={length}
                    onChange={(e) => setLength(e.target.value)}
                  />
                </div>
                <div className='mb-5 d-none'>
                  <label className='form-label'>Width</label>
                  <input
                    type='text'
                    className='form-control'
                    value={width}
                    onChange={(e) => setWidth(e.target.value)}
                  />
                </div>
                <div className='mb-5 d-none'>
                  <label className='form-label'>Cost</label>
                  <input
                    type='text'
                    className='form-control'
                    value={cost}
                    onChange={(e) => setCost(e.target.value)}
                  />
                </div>
                {/* <div className='mb-5 border-top pt-3'>
                  <label className='form-label'>Services</label>
                  <select
                    className='form-select'
                    multiple
                    value={services.split(',')}
                    onChange={(e) =>
                      setServices(
                        Array.from(e.target.selectedOptions, (option) => option.value).join(',')
                      )
                    }
                  >
                    {servicesOptions.map((service) => (
                      <option key={service.id} value={service.id}>
                        {service.name}
                      </option>
                    ))}
                  </select>
                </div> */}
                           <h3 className='mb-5'>Pictures Information :
              </h3>
              <label className='form-label'>Pictures <span className='text-danger'>*</span></label>

              <div className="toolbar">
                <div className='mb-5'>
              
                  <SingleImageUpload onImageChange={(file) => setSingleImage(file)} />
             
                </div>
                <div className='mb-5'>
                  <SingleImageUpload onImageChange={(file) => setSingleImage(file)} />
               
                </div>
                <div className='mb-5'>
                  <SingleImageUpload onImageChange={(file) => setSingleImage(file)} />
                 
                </div>
                <div className='mb-5'>
                  <SingleImageUpload onImageChange={(file) => setSingleImage(file)} />
                 
                </div>
                <div className='mb-5'>
                  <SingleImageUpload onImageChange={(file) => setSingleImage(file)} />
                 
                </div>
                <div className='mb-5'>
                  <SingleImageUpload onImageChange={(file) => setSingleImage(file)} />
                 
                </div>
                </div>


  
              </div>
              <div className='col-lg-6 mb-5'>
   
              <h3 className='mb-5'>Location Information :</h3>

<div className='mb-5'>
  <label className='form-label'>Type <span className='text-danger'>*</span></label>
  <div className='toolbar'>
  <div className='form-check form-check-custom form-check-solid'>
      <input
        className='form-check-input'
        type='radio'
        name='type'
        value='main'
        checked={type === 'main'}
        onChange={() => setType('main')}
      />
      <label className='form-check-label'>Main</label>
    </div>
    <div className='form-check form-check-custom form-check-solid'>
      <input
        className='form-check-input'
        type='radio'
        name='type'
        value='part'
        checked={type === 'part'}
        onChange={() => setType('part')}
      />
      <label className='form-check-label'>Part</label>
    </div>

  </div>
</div>
{type === 'main' && branchData && (
        <>
          <div className='row mb-5'>
            <div className='col-12 mb-5'>
              <label className='form-label'>Branch Name</label>
              <input
                type='text'
                className='form-control'
                value={branchData.name }
                disabled
              />
            </div>
            <div className='col-lg-6 mb-5'>
              <label className='form-label'>State</label>
              <select
                disabled
                className='form-select'
                value={branchData.state}
              >
                <option value={branchData.state}>{branchData.state}</option>
              </select>
            </div>
            <div className='col-lg-6 mb-5'>
              <label className='form-label'>City</label>
              <select
                disabled
                className='form-select'
                value={branchData.city}
              >
                <option value={branchData.city}>{branchData.city}</option>
              </select>
            </div>
            <div className='col-12 mb-5'>
              <label className='form-label'>Address</label>
              <input
                type='text'
                className='form-control'
                value={branchData.address || 'Address not provided'}
                disabled
              />
            </div>
          </div>
          <LocationPicker
            selectedCountry={'egypt'}
            selectedState={branchData.state}
            address={branchData.city}
            latitude={parseFloat(branchData.latitude)}
            longitude={parseFloat(branchData.longitude)}
            onLocationChange={handleLocationChange}
          />
        </>
      )}

{type === 'part' && (
                  <>
                <div className='mb-5'>
                  <label className='form-label'>Part From :</label>
                  <select
  className='form-select'
  value={partFrom}
  onChange={(e) => setPartFrom(e.target.value)}
>
  {mainFields.map((field) => (
    <option key={field.id} value={field.id}>
      {field.name}
    </option>
  ))}
</select>

                </div>
                <div className='mb-5'>
                  <label className='form-label'>Conflicted Fields</label>
                  <select
                    className='form-select'
                    
                    value={conflictedFields}
                    onChange={(e) =>
                      setConflictedFields(
                        Array.from(e.target.selectedOptions, (option) => option.value)
                      )
                    }
                  >
                    {mainFields.map((field) => (
                      <option key={field.id} value={field.id}>
                        {field.name}
                      </option>
                    ))}
                  </select>
                </div>
                  </>
                )}

                
         
           
          
          

                {/* <div className='mb-5'>
                  <label className='form-label'>Multiple Images Upload</label>
                  <ImageUpload2 onImagesChange={(files) => setImages(files)} />
                </div> */}
             
              </div>
              <div className="col-12">
              <div className='mb-5 border-top pt-3'>
    <label className='form-label'>Services <span className='text-danger'>*</span></label>
    <div className='form-check p-0'>
      {servicesOptions.map((service) => (
        <div key={service.id} className='form-check mb-3'>
          <input
            type='checkbox'
            id={`service-${service.id}`}
            value={service.id}
            onChange={(e) => {
              const newServices = [...services];
              
              if (e.target.checked) {
                newServices.push(e.target.value);
              } else {
                const index = newServices.indexOf(e.target.value);
                if (index > -1) {
                  newServices.splice(index, 1);
                }
              }

              setServices(newServices);
            }}
            className='form-check-input'
          />
          <label htmlFor={`service-${service.id}`} className='form-check-label'>
            {service.name}
          </label>
        </div>
      ))}
    </div>
    <p>Note: a new field will be under review then the status will be set as selected</p>
  </div>
              </div>
            </div>
   <div className="d-flex justify-content-between mt-5">
   <Link  to='/dashboard/fields/Branches' className='btn btn-light'>
              Cancel
            </Link>
   <button type='submit' className='btn btn-dark'>
              Submit
            </button>
   </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default AddNewField
