import React, { FC, useState, useEffect } from 'react'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import { setupAxios } from '../../../modules/auth/core/AuthHelpers'
import { Link } from 'react-router-dom'
import { KTIcon } from '../../../../_high-five/helpers'
import axios from 'axios'
import toast, { Toaster } from 'react-hot-toast'

const apiUrl = process.env.REACT_APP_API_URL
setupAxios(axios)

interface AccordionFieldProps {
  showEditOrder: boolean
  saveOrder: (orderNumbers: number[]) => void
  status: string
  branch: string
  refreshData: boolean
  onRefreshComplete: () => void
}

interface AccordionItem {
  id: number
  name: string
  status: string
  type: string
  bodyContent: JSX.Element
  statusClass: string
  avatar: string
  order: number
  images: []
}

interface UnitParams {
  branch_id: string
  status: string
}

const AccordionField: FC<AccordionFieldProps> = ({ showEditOrder, saveOrder, status, branch, refreshData, onRefreshComplete }) => {
  const [accordionItems, setAccordionItems] = useState<AccordionItem[]>([])
  const [orderNumbers, setOrderNumbers] = useState<AccordionItem[]>([])
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null)
  const [selectedStatus, setSelectedStatus] = useState<string>('')
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchAccordionItems = async () => {
      setIsLoading(true)

      try {
        const params: UnitParams = {
          branch_id: branch,
          status,
        }
        const response = await axios.get(`${apiUrl}/units`, { params })
        const items = response.data.data

        const mappedItems = items.map((item) => ({
          id: item.id,
          name: item.name,
          images: item.images,
          avatar: item.avatar,
          status: item.status,
          type: item.type,
          order: item.order,
          services: item.services, // Add this line to capture services from API response

          bodyContent: (
     
              <div className='container-fluid'>
                <Toaster/>
                <div className='row'>
                  <div className='col-md-4'>
                    <ul className='list-unstyled'>
                      <li className='mb-7'>
                        <Link 
                        to={`/dashboard/fields/SessionMangement?unit_id=${item.id}`}
                        className='btn   p-0'>
                          <KTIcon iconName='double-right' className='fs-2 text-lime' /> Session
                          Management
                        </Link>
                      </li>
            
                      <li className='mb-7'>
                        <Link
                           to={`/dashboard/fields/AssigendEmployee?unit_id=${item.id}`}
                        
                        className='btn   p-0'>
                          <KTIcon iconName='double-right' className='fs-2 text-lime' /> Assigned
                          Employees <span className='bad_fild'>{item.employees}</span>
                        </Link>
                      </li>
                      <li className='mb-7'>
                        <Link
                          to={`/dashboard/fields/BookingMehodology?unit_id=${item.id}`}
                          className='btn   p-0'
                        >
                          <KTIcon iconName='double-right' className='fs-2 text-lime' /> Booking
                          Methodology <span className='bad_fild'>{item.booking_methodology}</span>
                        </Link>
                      </li>
            
                      <li className=''>
                        <Link
                          to={`/dashboard/fields/comments?unit_id=${item.id}`}
                          className='btn   p-0'
                        >
                          <KTIcon iconName='double-right' className='fs-2 text-lime' /> Comments{' '}
                          <span className='bad_fild'>{item.comments}</span>
                        </Link>
                      </li>
                      <li>
                        <h3 className='mb-2 mt-5'>Description :</h3>
                        <p className='text-muted mb-2'> {item.description}</p>
                      </li>
                   
                    </ul>
                  </div>
                  <div className='col-md-4'>
                    <h3 className='mb-2'>General Information</h3>
            
                    <p className='d-flex gap-3  align-items-start mb-1 toolbar'>
                      <span  style={{minWidth: '70px' }}>Category :</span> <span  className='text-muted' style={{ fontSize: '12px' }}> {item.category}</span>
                    </p>
                    <p className='d-flex gap-3  align-items-start mb-1 toolbar'>
                      <span  style={{minWidth: '70px' }}>Activity :</span> <span  className='text-muted' style={{ fontSize: '12px' }}> {item.activity}</span>
            
                    </p>
                    <p className='d-flex gap-3  align-items-start mb-1 toolbar'>
                      <span  style={{minWidth: '70px' }}>Type :</span> <span  className='text-muted' style={{ fontSize: '12px' }}> {item.floor_type}</span>
            
                    </p>
                    <p className='d-flex gap-3  align-items-start mb-1 toolbar'>
                    <span  style={{minWidth: '70px' }}>Capacity :</span> <span  className='text-muted' style={{ fontSize: '12px' }}> {item.capacity}</span>
            
                    </p>
                    <p className='d-flex gap-3  align-items-start mb-1 toolbar'>
                    <span  style={{minWidth: '70px' }}>Gender :</span> <span  className='text-muted' style={{ fontSize: '12px' }}> {item.gender}</span>
            
                    </p>
                    <h3 className='my-3'>Services :</h3>
                    <p className='text-muted mb-2 toolbar'>
    {item.services && item.services.length > 0 ? (
      item.services.map((service, idx) => (
        <span key={idx} className='bad_fild m-0'>
          {service}
        </span>
      ))
    ) : (
      <span className='text-muted'>No services added</span>
    )}
  </p>
                        
                  </div>
                  <div className='col-md-4'>
                    <div
                      id='kt_carousel_1_carousel'
                      className='carousel carousel-custom slide'
                      data-bs-ride='carousel'
                      data-bs-interval='8000'
                    >
                      <div className='d-flex align-items-center justify-content-between flex-wrap'>
                      <div className='carousel-inner'>
  {item.images.map((image, idx) => (
    <div
      className={`carousel-item ${idx === 0 ? 'active' : ''}`}
      key={idx}
    >
      <img src={image} alt={`Slide ${idx + 1}`} className='w-100 img-fluid accord_img' />
    </div>
  ))}
</div>
                      </div>
                      <ol className='p-0 m-0 carousel-indicators carousel-indicators-dots'>
  {item.images.map((_, idx) => (
    <li
      key={idx}
      data-bs-target={`#kt_carousel_${item.id}_carousel`}
      data-bs-slide-to={idx}
      className={idx === 0 ? 'ms-1 active' : 'ms-1'}
    ></li>
  ))}
</ol>
                    </div>
                  </div>
                </div>
              </div>
        
          ),
          statusClass:
            item.status === 'Activated'
              ? 'badge_custom-active'
              : item.status === 'Waiting For Approval'
              ? 'badge_custom-wait'
              : 'badge_custom-cancel',
        }))

        setAccordionItems(mappedItems)
        setOrderNumbers(mappedItems)
      } catch (error) {
        console.error('Error fetching units:', error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchAccordionItems()

    if (refreshData) {
      onRefreshComplete()
    }
  }, [branch, status, refreshData])

  const openModal = (itemId: number) => {
    const item = accordionItems.find((item) => item.id === itemId)
    if (item) {
      setSelectedItemId(item.id)
      setSelectedStatus(item.status)
    }
  }

  const handleStatusChange = (newStatus: string) => {
    setSelectedStatus(newStatus)
  }

  const saveStatus = async () => {
    if (selectedItemId !== null) {
      try {
        const response = await axios.post(`${apiUrl}/units/${selectedItemId}/status`, {
          status: selectedStatus,
        })
        console.log('Status updated:', response.data)

        const updatedItems = accordionItems.filter((item) => item.id !== selectedItemId)

        const updatedItem = accordionItems.find((item) => item.id === selectedItemId)
        if (updatedItem) {
          updatedItem.status = selectedStatus
          setAccordionItems(updatedItems)
        }
        toast.success('Status updated successfully!')

        setSelectedItemId(null)
        setSelectedStatus('')
      } catch (error) {
        console.error('Error updating status:', error)
        toast.error('Failed to update status.')
      }
    }
  }
  const handleOrderNumberChange = (index: number, value: string) => {
    const maxOrder = accordionItems.length;
    let newOrderValue = parseInt(value, 10);
  
    if (newOrderValue > maxOrder) {
      newOrderValue = maxOrder;
      toast.error(`You cannot set a number higher than ${maxOrder}.`);
    }
  
    const updatedOrderNumbers = [...orderNumbers];
    updatedOrderNumbers[index].order = newOrderValue;
    setOrderNumbers(updatedOrderNumbers);
  
    // Send the updated order data to the API
    const reorderedData = updatedOrderNumbers.map(item => ({
      id: item.id,
      order: item.order,
    }));
  
    axios.post(`${apiUrl}/units/reorder`, reorderedData)
      .then(response => {
        console.log('Order updated successfully', response.data);
        toast.success('Order updated successfully!');
      })
      .catch(error => {
        console.error('Error updating order', error);
        toast.error('Failed to update order.');
      });
  };
  
  // const handleOrderNumberChange = (index: number, value: string) => {
  //   const updatedOrderNumbers = [...orderNumbers]
  //   updatedOrderNumbers[index].order = parseInt(value, 10)
  //   setOrderNumbers(updatedOrderNumbers)

  //   // Send the updated order data to the API
  //   const reorderedData = updatedOrderNumbers.map(item => ({
  //     id: item.id,
  //     order: item.order
  //   }))

  //   axios.post(`${apiUrl}/units/reorder`, reorderedData)
  //     .then(response => {
  //       console.log('Order updated successfully', response.data)
  //       toast.success('Order updated successfully!')
  //     })
  //     .catch(error => {
  //       console.error('Error updating order', error)
  //       toast.error('Failed to update order.')
  //     })
  // }

  const renderAccordionItems = () => {
    return accordionItems.map((item, index) => (
      <div className='accordion-item shadow-sm' key={item.id}>
           <Toaster/>
        <h2 className='accordion-header' id={`kt_accordion_1_header_${item.id}`}>
          <button
            className='accordion-button fs-4 fw-bold p-3'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target={`#kt_accordion_1_body_${item.id}`}
            aria-expanded='false'
            aria-controls={`kt_accordion_1_body_${item.id}`}
          >
            <div className='d-flex justify-content-between w-100 align-items-center'>
              <span>
                <img src={item.avatar} alt='' className='w-40px h-40px me-2 object-fit-contain' />
                {item.name}
              </span>
              {showEditOrder && (
                <input
                  type='number'
                  className='form-control w-25'
                  value={item.order}
                  onChange={(e) => handleOrderNumberChange(index, e.target.value)}
                />
              )}

              <span
                className={`d-flex justify-content-center align-items-center gap-5 text-muted ${item.statusClass}`}
              >
                {item.type}
                <span
                  id={`button-tooltip_${item.id}`}
                  data-tooltip-content={item.status}
                  className={`badge_custom ${item.statusClass} me-2`}
                ></span>
                <button
                  className='btn  text-center '
                  onClick={() => openModal(item.id)}
                  data-bs-toggle='modal'
                  data-bs-target={`#kt_modal_${item.id}`}
                >
                  <i className='fa-solid fa-ellipsis-vertical p-0'></i>
                </button>
              </span>
            </div>
            <Tooltip
              anchorId={`button-tooltip_${item.id}`}
              place='top'
              className='custom-tooltip'
            />
          </button>
        </h2>
        <div
          id={`kt_accordion_1_body_${item.id}`}
          className='accordion-collapse collapse'
          aria-labelledby={`kt_accordion_1_header_${item.id}`}
          data-bs-parent='#kt_accordion_1'
        >
          <div className='accordion-body'>{item.bodyContent}</div>
        </div>
        <div className='modal fade' tabIndex={-1} id={`kt_modal_${item.id}`}>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Update Status</h5>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  x
                </div>
              </div>
              <div className='modal-body'>
                <p>Field Status :</p>
                <div className='d-flex gap-10'>
                  <div className='form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='radio'
                      value='Maintains'
                      id={`maintains_${item.id}`}
                      name='status'
                      checked={selectedStatus === 'Maintains'}
                      onChange={() => handleStatusChange('Maintains')}
                    />
                    <label className='form-check-label' htmlFor={`maintains_${item.id}`}>
                      Maintains
                    </label>
                  </div>
                  <div className='form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='radio'
                      value='Available'
                      id={`available_${item.id}`}
                      name='status'
                      checked={selectedStatus === 'Available'}
                      onChange={() => handleStatusChange('Available')}
                    />
                    <label className='form-check-label' htmlFor={`available_${item.id}`}>
                      Available
                    </label>
                  </div>
                </div>
              </div>
              <div className='modal-footer justify-content-between'>
                <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                  Close
                </button>
                <button
                  type='button'
                  className='btn btn-dark'
                  data-bs-dismiss='modal'
                  onClick={saveStatus}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    ))
  }

  return (
    <div className='accordion mt-10 mb-10' id='kt_accordion_1'>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        renderAccordionItems()
      )}
      <Toaster />
    </div>
  )
}

export { AccordionField }
