import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import axios from 'axios'
import {Link} from 'react-router-dom'
import {KTIcon, toAbsoluteUrl} from '../../../../_high-five/helpers'
import {fetchBranches} from '../../../services/branchesService'

const Reservation: FC = () => {
  const intl = useIntl()
  const [selectedTab, setSelectedTab] = useState<string>('coming')
  const [bookings, setBookings] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedBranch, setSelectedBranch] = useState<number | null>(null)
  //selectedBranchId
  const [selectedBranchId, setSelectedBranchId] = useState<number | null>(null)
  const [selectedUnit, setSelectedUnit] = useState<number | null>(null)
  const [selectedBooking, setSelectedBooking] = useState<number | null>(null)
  const [selectedBookingType, setSelectedBookingType] = useState<string | null>(null)
  const [openAccordion, setOpenAccordion] = useState<string | null>(null)
  const [units, setUnits] = useState<any[]>([])
  const [branches, setBranches] = useState<any[]>([])
  const [bookingType, setBookingType] = useState<string>('')
  const [type, setType] = useState<string>('coming')
  const apiUrl = process.env.REACT_APP_API_URL
  const bookingTypes = ['online', 'linked', 'walk-in', 'public_event']

  // Fetch data based on the selected tab
  const fetchBookings = async (type: string) => {
    setLoading(true)
    try {
      setType(type)
      const response = await axios.get(`${apiUrl}/bookings`, {
        params: {
          type: type, // type can be 'coming', 'past', or 'canceled'
        },
      })

      if (response.data.success) {
        setBookings(response.data.data.items)
      }

      //fetch branches
      const branches = await axios.get(`${apiUrl}/general/branches`)
      setBranches(branches.data.data)
    } catch (error) {
      console.error('Error fetching bookings:', error)
    }
    setLoading(false)
  }

  useEffect(() => {
    // Fetch data when the selected tab changes
    fetchBookings(selectedTab)
  }, [selectedTab])

  // Helper function to change the selected tab
  const handleTabChange = (tab: string) => {
    setSelectedTab(tab)
  }
  const fetchBookingWithQueryParams = async () => {
    const paramsData: {
      booking_type?: string;
      unit_id?: number;
      branch_id?: number;
      type?: string;
    } = {};

    if (type) {
      paramsData.type = type;
    }

    if (selectedBookingType) {
      paramsData.booking_type = selectedBookingType;
    }

    if (selectedUnit) {
      paramsData.unit_id = selectedUnit; // Define if it exists
    }

    console.log(selectedBranchId, 'selected branch id');
    if (selectedBranchId !== null) {
      paramsData.branch_id = selectedBranchId; // Define if it exists
    } else {
      delete paramsData.branch_id; // Remove branch_id if it doesn't exist
    }

    try {
      const response = await axios.get(`${apiUrl}/bookings`, {
        params: paramsData,
      });
      setBookings(response.data.data.items);
    } catch (error) {
      console.error('Error fetching bookings:', error);
    }
  };

  const handleBranchChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const branchId = parseInt(event.target.value, 10);
    const value = event.target.value; // Get the selected value

    if (value === 'all') {
      setSelectedBranchId(null);
      setSelectedBranch(null);
      const unitsData = await axios.get(`${apiUrl}/general/units`);
      setUnits(unitsData.data.data);
    } else {
      setSelectedBranch(branchId);
      setSelectedBranchId(branchId);
      const unitsData = await axios.get(`${apiUrl}/general/units?branch_id=${branchId}`);
      setUnits(unitsData.data.data);
    }

    // fetchBookingWithQueryParams();
    setSelectedUnit(null); // Reset unit selection when branch changes
  };

  useEffect(() => {
    fetchBookingWithQueryParams();
  }, [selectedBranchId, selectedUnit, selectedBookingType]); // Add other dependencies if needed


  const handleUnitChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const unitId = parseInt(event.target.value, 10)
    const value = event.target.value // Get the selected value
  
    if (value == 'all') {
      setSelectedUnit(null)
    } else {
      setSelectedUnit(unitId)
    }

    setSelectedUnit(unitId)
    
  }
  const handleBookingsChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value // Get the selected value

    if (value === 'all') {
      setSelectedBookingType(null) // Handle the "all" case
    } else {
      setSelectedBookingType(value) // Set the numeric booking ID
    }
  }

  // Helper function to get link URL
  const getLinkUrl = (type: string) => {
    if (type === 'public event') {
      return '/dashboard/reservation/PublicDetailsPast'
    } else if (type === 'booking') {
      return '/dashboard/reservation/BookingDetails'
    }
    return '#'
  }

  return (
    <div className='pt-5'>
      <h1>Bookings</h1>
      <div className='card p-3 shadow-sm my-4'>
        <ul className='nav nav-tabs nav-line-tabs mb-0 fs-6  justify-content-center border-0'>
          <li className='nav-item'>
            <a
              className={`nav-link ${selectedTab === 'coming' ? 'active' : ''}`}
              onClick={() => handleTabChange('coming')}
              role='button'
            >
              Coming
            </a>
          </li>
          <li className='nav-item'>
            <a
              className={`nav-link ${selectedTab === 'past' ? 'active' : ''}`}
              onClick={() => handleTabChange('past')}
              role='button'
            >
              Past
            </a>
          </li>
          <li className='nav-item'>
            <a
              className={`nav-link ${selectedTab === 'canceled' ? 'active' : ''}`}
              onClick={() => handleTabChange('canceled')}
              role='button'
            >
              Canceled
            </a>
          </li>
        </ul>
      </div>
      <div className='row mb-4'>
        <div className='col-lg-4'>
          <div className='card'>
            <div className='input-group  p-2'>
              <select
                className='form-select  rounded  me-2'
                value={selectedBranch || 'all'}
                onChange={handleBranchChange}
                aria-label='Select Branch'
              >
                <option value='all'>all</option>
                {branches.map((branch) => (
                  <option key={branch.id} value={branch.id}>
                    {branch.name}
                  </option>
                ))}
              </select>
              <div className='input-group-prepend'>
                <span className='input-group-text bg-transparent border-0 p-0' id='basic-addon1'>
                  <button className='btn btn-dark fs-7 px-3'>
                    {' '}
                    Branch
                    <KTIcon iconName='filter' className='fs-7  mx-1' />
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-4'>
          <div className='card'>
            <div className='input-group  p-2'>
              <select
                className='form-select rounded  me-2'
                value={selectedUnit || ''}
                onChange={handleUnitChange}
                aria-label='Select Unit'
                disabled={!selectedBranch}
              >
                <option value=''>all</option>
                {selectedBranch &&
                  units?.map((unit) => (
                    <option key={unit.id} value={unit.id}>
                      {unit.name}
                    </option>
                  ))}
              </select>
              <div className='input-group-prepend'>
                <span className='input-group-text bg-transparent border-0 p-0' id='basic-addon1'>
                  <button className='btn btn-dark fs-7 px-3'>
                    {' '}
                    Unit
                    {/* <KTIcon iconName='filter' className='fs-7  mx-1' /> */}
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className='col-lg-4'>
          <div className='card'>
            <div className='input-group  p-2'>
              <select
                className='form-select rounded me-2'
                value={selectedBookingType || ''}
                onChange={handleBookingsChange}
                aria-label='Select Booking'
              >
                <option value='all'>all</option> {/* Explicitly set a string value */}
                {bookingTypes.map((booking) => (
                  <option key={booking} value={booking}>
                    {booking}
                  </option>
                ))}
              </select>
              <div className='input-group-prepend'>
                <span className='input-group-text bg-transparent border-0 p-0' id='basic-addon1'>
                  <button className='btn btn-dark fs-7 px-3'>
                    {' '}
                    Type
                    <KTIcon iconName='filter' className='fs-7  mx-1' />
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Loading State */}
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className='tab-content'>
          <div className='tab-pane fade active show'>
            <div className='card'>
              <div className='card-body p-3'>
                <div className='table-responsive'>
                  <table className='table align-middle gs-0 gy-4 branch_table_start'>
                    <thead>
                      <tr className='fw-bold text-muted bg-dark'>
                        <th className='rounded-start text-center'>ID</th>
                        <th>Type</th>
                        <th>Category / Activity</th>
                        <th>Branch / Unit</th>
                        <th>Created on</th>
                        <th>Start time / End Time</th>
                        <th>Users</th>
                        <th className='rounded-end'>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bookings.length > 0 ? (
                        bookings.map((booking) => (
                          <tr key={booking.id}>
                            <td className='text-center'>{booking.id}</td>
                            <td>
                              {booking.type_translated}{' '}
                              <span className='badge badge-info mx-2'>{booking.type}</span>
                            </td>
                            <td>
                              {booking.category} <br />
                              {booking.activity}
                            </td>
                            <td>
                              {booking.branch} <br />
                              {booking.unit}
                            </td>
                            <td>{booking.created_at}</td>
                            <td>
                              {booking.start_at} <br />
                              {booking.end_at}
                            </td>
                            <td>{booking.users ? booking.users : 'N/A'}</td>
                            <td>
                      {/* {booking} */}
      {booking.type === 'public_event' ? (
        // <Link
        //   to={"/dashboard/reservation/PublicEvent?bookingId=" + booking.id}
        //   className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
        // >
        //   <i className="fas fa-eye"></i>
        // </Link>
        <div></div>
      ) : (
        <Link
          to={"/dashboard/reservation/BookingDetails?bookingId="+ booking.id} 
          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
        >
          <i className="fas fa-eye"></i> 
        </Link>
      )}
    </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={8} className='text-center'>
                            No bookings available.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export {Reservation}