/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {Link} from 'react-router-dom'

type Props = {
  className: string
}

const PublicHistoryTable: React.FC<Props> = ({className}) => {
  return (
    <div className={`card ${className} bg-transparent shadow-none border-0`}>
      {/* <div className='card-header border-0 p-0 justify-content-end'>

     
             
             <Link to='/dashboard/management/policies/PoiciesDetails'  className='btn btn-sm btn-dark h-40px d-flex align-items-center'>
             <KTIcon iconName='plus' className='fs-2' />
             New 



   </Link>
    
      </div> */}
      <div className='card-body p-3 '>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted bg-dark'>
                <th className='rounded-start'>Player</th>
          
                <th >Type</th>
                <th>Amount</th>
          
          <th className='rounded-end'>Date</th>
              </tr>
            </thead>
      
            <tbody>
              <tr>
            
                <td>
                  <span  className='text-dark fw-bold  d-block mb-1 fs-6'>
                                 </span>
                </td>
                <td>
                  <span  className='text-dark fw-bold  d-block mb-1 fs-6'>
                                 </span>
                </td>
                <td>
                  <span  className='text-dark fw-bold  d-block mb-1 fs-6'>
                                 </span>
                </td>
                <td>
                  <span  className='text-dark fw-bold  d-block mb-1 fs-6'>
                                 </span>
                </td>
              </tr>
        
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export {PublicHistoryTable}
