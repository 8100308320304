import {FC} from 'react'
import {useIntl} from 'react-intl'
import Breadcrumb from '../../../../_high-five/partials/widgets/bread_crumb/Breadcrumb'
import {PublicHistoryTable} from '../../../../_high-five/partials/widgets/custom_table/PublicHistoryTable'
import { toAbsoluteUrl} from '../../../../_high-five/helpers'

const PublicDetailsPast: FC = () => {
  const intl = useIntl()

  return (
    <>
      <div className='pt-5'>
        <h1>VIEW PUBLIC EVENT</h1>
        <Breadcrumb secondItem=' Page' />
        <div className='row'>
          <div className='col-lg-6'>
            <div className='card p-4 min-h-card'>
              <h4 className=' border-bottom pb-4  pt-2 mb-3 text-lime'>
                Session Information (#8838){' '}
              </h4>
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Type :</span> <span>Fixed Session</span>
              </p>
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Date :</span> <span>Sunday - 14 Jul, 2024</span>
              </p>

              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Start :</span> <span>2:00 AM</span>
              </p>

              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>End :</span> <span>2:00 AM</span>
              </p>

              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Gender :</span> <span>Male</span>
              </p>
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Price :</span>{' '}
                <span>
                  <i className='fa-solid fa-circle-info px-2'></i>Price of the session is not used
                  in public event.
                  {/* <button  className='btn p-0' data-bs-toggle="modal"
  data-bs-target="#kt_modal_1"></button> */}
                </span>
              </p>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='card p-4 min-h-card'>
              <h4 className=' border-bottom pb-4  pt-2 mb-3 text-lime'>Public Event (#253) </h4>
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Public Event Status :</span> <span>Past</span>
              </p>
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Number of player :</span> <span>15</span>
              </p>

              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Age Group :</span> <span>Open</span>
              </p>

              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Created By :</span> <span>Murtada Company 2 Test</span>
              </p>

              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Created At :</span>{' '}
                <span>10:45 PM - Wednesday - 10 Jul, 24</span>
              </p>
            </div>
          </div>

          <div className="col-lg-12">
         
            <div className='accordion mt-5' id='kt_accordion_1'>
<div className='accordion-item'>
  <h2 className='accordion-header' id='kt_accordion_1_header_1'>
    <button
      className='accordion-button fs-4 fw-bold collapsed'
      type='button'
      data-bs-toggle='collapse'
      data-bs-target='#kt_accordion_1_body_1'
      aria-expanded='false'
      aria-controls='kt_accordion_1_body_1'
    >
            <h4 className=' mb-3 text-lime'><span>Joined Players (0/15)</span></h4>
            </button>
  </h2>
  <div
    id='kt_accordion_1_body_1'
    className='accordion-collapse collapse'
    aria-labelledby='kt_accordion_1_header_1'
    data-bs-parent='#kt_accordion_1'
  >
    <div className='accordion-body'>
                    <div className="row">
                      <div className="col-lg-3">
                      <div className="d-flex gap-3 align-items-center border p-3 rounded">
                <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/logo.png')}
                    className='h-50px w-50px rounded-circle border'
                  />
                <div className="">
            
                  <span  className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                  murtada           </span>
                  <span  className='text-dark fw-bold text-hover-primary d-block mb-1 fs-8'>
                  @murtada71           </span>
                  <span  className='text-dark fw-bold text-hover-primary d-block mb-1 fs-8'>
                  23:51 PM - Wednesday - 24 04, 2024          </span>
                  </div>
                </div>
                      </div>
                      <div className="col-lg-3">
                      <div className="d-flex gap-3 align-items-center border p-3 rounded">
                <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/logo.png')}
                    className='h-50px w-50px rounded-circle border'
                  />
                <div className="">
            
                  <span  className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                  murtada           </span>
                  <span  className='text-dark fw-bold text-hover-primary d-block mb-1 fs-8'>
                  @murtada71           </span>
                  <span  className='text-dark fw-bold text-hover-primary d-block mb-1 fs-8'>
                  23:51 PM - Wednesday - 24 04, 2024          </span>
                  </div>
                </div>
                      </div>
                      <div className="col-lg-3">
                      <div className="d-flex gap-3 align-items-center border p-3 rounded">
                <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/logo.png')}
                    className='h-50px w-50px rounded-circle border'
                  />
                <div className="">
            
                  <span  className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                  murtada           </span>
                  <span  className='text-dark fw-bold text-hover-primary d-block mb-1 fs-8'>
                  @murtada71           </span>
                  <span  className='text-dark fw-bold text-hover-primary d-block mb-1 fs-8'>
                  23:51 PM - Wednesday - 24 04, 2024          </span>
                  </div>
                </div>
                      </div>
                      <div className="col-lg-3">
                      <div className="d-flex gap-3 align-items-center border p-3 rounded">
                <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/logo.png')}
                    className='h-50px w-50px rounded-circle border'
                  />
                <div className="">
            
                  <span  className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                  murtada           </span>
                  <span  className='text-dark fw-bold text-hover-primary d-block mb-1 fs-8'>
                  @murtada71           </span>
                  <span  className='text-dark fw-bold text-hover-primary d-block mb-1 fs-8'>
                  23:51 PM - Wednesday - 24 04, 2024          </span>
                  </div>
                </div>
                      </div>
                    </div>
    </div>
  </div>
</div>


</div>

          </div>
          <div className="col-lg-12">
          <div className='accordion' id='kt_accordion_1'>

<div className='accordion-item'>
  <h2 className='accordion-header' id='kt_accordion_1_header_2'>
    <button
      className='accordion-button fs-4 fw-bold collapsed'
      type='button'
      data-bs-toggle='collapse'
      data-bs-target='#kt_accordion_1_body_2'
      aria-expanded='false'
      aria-controls='kt_accordion_1_body_2'
    >
                 <h4 className=' mb-3 text-lime'><span>Left Players Early (0)</span></h4>

    </button>
  </h2>
  <div
    id='kt_accordion_1_body_2'
    className='accordion-collapse collapse'
    aria-labelledby='kt_accordion_1_header_2'
    data-bs-parent='#kt_accordion_1'
  >
    <div className='accordion-body'>

    </div>
  </div>
</div>

</div>
          </div>
          <div className="col-lg-12">
          <div className='accordion' id='kt_accordion_1'>

<div className='accordion-item'>
  <h2 className='accordion-header' id='kt_accordion_1_header_3'>
    <button
      className='accordion-button fs-4 fw-bold collapsed'
      type='button'
      data-bs-toggle='collapse'
      data-bs-target='#kt_accordion_1_body_3'
      aria-expanded='false'
      aria-controls='kt_accordion_1_body_3'
    >
                     <h4 className=' mb-3 text-lime'><span>Left Players (Late) (0)</span></h4>

    </button>
  </h2>
  <div
    id='kt_accordion_1_body_3'
    className='accordion-collapse collapse'
    aria-labelledby='kt_accordion_1_header_3'
    data-bs-parent='#kt_accordion_1'
  >
    <div className='accordion-body'>

    </div>
  </div>
</div>
</div>
          </div>
          <div className="col-lg-12">
          <div className='accordion' id='kt_accordion_1'>

<div className='accordion-item'>
  <h2 className='accordion-header' id='kt_accordion_1_header_4'>
    <button
      className='accordion-button fs-4 fw-bold collapsed'
      type='button'
      data-bs-toggle='collapse'
      data-bs-target='#kt_accordion_1_body_4'
      aria-expanded='false'
      aria-controls='kt_accordion_1_body_4'
    >
                     <h4 className=' mb-3 text-lime'><span>Removed Players (0)</span></h4>

    </button>
  </h2>
  <div
    id='kt_accordion_1_body_4'
    className='accordion-collapse collapse'
    aria-labelledby='kt_accordion_1_header_4'
    data-bs-parent='#kt_accordion_1'
  >
    <div className='accordion-body'>
     test
    </div>
  </div>
</div>
</div>
          </div>
          <div className='col-lg-12'>
            <div className='card p-4 min-h-card mt-5 sum_width'>
              <h4 className=' border-bottom pb-4  pt-2 mb-3 text-lime'>
                <span>Payment</span>
              </h4>
              <h2 className='my-3'>Details</h2>

              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Payment Method :</span> <span>online</span>
              </p>
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Price per user :</span>{' '}
                <span>
                  100.00 USD
                  <button className='btn py-0' data-bs-toggle='modal' data-bs-target='#kt_modal_1'>
                    <i className='fa-solid fa-circle-info'></i>
                  </button>
                </span>
              </p>
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Charge for leave within :</span> <span>3</span>
              </p>

              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Cancelation Fee per user :</span>{' '}
                <span>50.00 USD( 50% )</span>
              </p>
              <h2 className='my-3'>Summery</h2>

              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Total payment from joined players :</span>{' '}
                <span>
                  0.00 USD
                  <button className='btn py-0' data-bs-toggle='modal' data-bs-target='#kt_modal_2'>
                    <i className='fa-solid fa-circle-info'></i>
                  </button>
                </span>
              </p>
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Total fee from Left players :</span>{' '}
                <span>
                  0.00 USD
                  <button className='btn py-0' data-bs-toggle='modal' data-bs-target='#kt_modal_3'>
                    <i className='fa-solid fa-circle-info'></i>
                  </button>
                </span>
              </p>

              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Total collected income from public event :</span>{' '}
                <span>
                  0.00 USD
                  <button className='btn py-0' data-bs-toggle='modal' data-bs-target='#kt_modal_4'>
                    <i className='fa-solid fa-circle-info'></i>
                  </button>
                </span>
              </p>

              <h2 className='my-3'>Payment History</h2>

              <PublicHistoryTable className='' />
            </div>
          </div>
        </div>
        {/* modal 1 */}
        <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Price per user Break Down</h5>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  x
                </div>
              </div>
              <div className='modal-body'>
                <div className=' border-bottom pb-3 mb-3'>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Base Price :</span> <span>20.00 EGP</span>
                  </p>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Discount (%) :</span> <span>20.00 EGP</span>
                  </p>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Public Event Price :</span> <span>20.00 EGP</span>
                  </p>
                </div>
                <div className=' border-bottom pb-3 mb-3'>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Tax (0%) :</span> <span>0.00 EGP</span>
                  </p>
                </div>
                <div className=' '>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Total :</span> <span>22.40 EGP</span>
                  </p>
                </div>
              </div>
              <div className='modal-footer'>
                <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                  Close
                </button>
                {/* <button type="button" className="btn btn-primary">
          Save changes
        </button> */}
              </div>
            </div>
          </div>
        </div>
        {/* modal 2 */}
        <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Total Payment from joined players Break Down</h5>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  x
                </div>
              </div>
              <div className='modal-body'>
                <div className=' border-bottom pb-3 mb-3'>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Total Number of Players :</span> <span>20.00 EGP</span>
                  </p>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Price per player :</span> <span>20.00 EGP</span>
                  </p>
                </div>
                <div className=' border-bottom pb-3 mb-3'>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Base Price :</span> <span>20.00 EGP</span>
                  </p>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Discount (0.00)% :</span> <span>20.00 EGP</span>
                  </p>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Public Event Price :</span> <span>20.00 EGP</span>
                  </p>
                </div>
                <div className=' border-bottom pb-3 mb-3'>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Tax (0%) :</span> <span>0.00 EGP</span>
                  </p>
                </div>
                <div className=' '>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Total :</span> <span>22.40 EGP</span>
                  </p>
                </div>
              </div>
              <div className='modal-footer'>
                <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                  Close
                </button>
                {/* <button type="button" className="btn btn-primary">
          Save changes
        </button> */}
              </div>
            </div>
          </div>
        </div>
        {/* modal 3 */}
        <div className='modal fade' tabIndex={-1} id='kt_modal_3'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Total Fee from Left players Break Down</h5>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  x
                </div>
              </div>
              <div className='modal-body'>
                <div className=' border-bottom pb-3 mb-3'>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Total Number of left players with fee :</span>{' '}
                    <span>20.00 EGP</span>
                  </p>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Fee Price per player :</span> <span>20.00 EGP</span>
                  </p>
                </div>

                <div className=' '>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Total :</span> <span>22.40 EGP</span>
                  </p>
                </div>
              </div>
              <div className='modal-footer'>
                <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                  Close
                </button>
                {/* <button type="button" className="btn btn-primary">
          Save changes
        </button> */}
              </div>
            </div>
          </div>
        </div>
        {/* modal 4*/}
        <div className='modal fade' tabIndex={-1} id='kt_modal_4'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Total income from public event Break Down</h5>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  x
                </div>
              </div>
              <div className='modal-body'>
                <div className=' border-bottom pb-3 mb-3'>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Total Number of Players :</span> <span>20.00 EGP</span>
                  </p>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Price per player :</span> <span>20.00 EGP</span>
                  </p>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Total Number of left players with fee :</span>{' '}
                    <span>20.00 EGP</span>
                  </p>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Fee Price per player :</span> <span>20.00 EGP</span>
                  </p>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Base Price :</span> <span>20.00 EGP</span>
                  </p>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Discount (0.00%):</span> <span>20.00 EGP</span>
                  </p>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Public Event Price :</span> <span>20.00 EGP</span>
                  </p>
                </div>
                <div className=' border-bottom pb-3 mb-3'>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Tax (0%) :</span> <span>0.00 EGP</span>
                  </p>
                </div>
                <div className=' '>
                  <p className='d-flex gap-3 align-items-center justify-content-between'>
                    <span className='min_w'>Total :</span> <span>22.40 EGP</span>
                  </p>
                </div>
              </div>
              <div className='modal-footer'>
                <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                  Close
                </button>
                {/* <button type="button" className="btn btn-primary">
          Save changes
        </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {PublicDetailsPast}
