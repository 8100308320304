import React, {FC, useState, useEffect,useRef} from 'react'
import {useIntl} from 'react-intl'
import Breadcrumb from '../../../../_high-five/partials/widgets/bread_crumb/Breadcrumb'
import axios from 'axios'
import {useLocation, useNavigate} from 'react-router-dom'
import toast, {Toaster} from 'react-hot-toast' // Import React Hot Toast
import CustomTimePicker from '../../../../_high-five/partials/widgets/calender/CustomTimePicker'
const apiUrl = process.env.REACT_APP_API_URL

interface SessionDetails {
  id: number
  type: string
  date: string
  start_time: string
  end_time: string
  gender: string
  price: {
    total_price: number
    base_price: number
    discount: number
    discount_percent: number
    tax: number
    tax_percent: number
  }
}

const Session: FC = () => {
  const intl = useIntl()
  const location = useLocation()
  const navigate = useNavigate() // Assign the navigate function
  const queryParams = new URLSearchParams(location.search)
  const sessionIdQueryParam = queryParams.get('sessionId')
  const unitQueryParam = queryParams.get('unitId')
  const [sessionDetails, setSessionDetails] = useState<SessionDetails | null>(null)
  const [startTime, setStartTime] = useState<string>('09:30')
  const [endTime, setEndTime] = useState<string>('')
  const [overnight, setOvernight] = useState<string| number>(0)
  const [modalMode] = useState<string>('edit') // Assuming modalMode is set to 'edit'
  const [price, setPrice] = useState<number>(0)
  const [gender, setGender] = useState<string>('male')
  const editModalRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const fetchSessionDetails = async () => {
      try {
        const {data} = await axios.get(
          `${apiUrl}/units/${unitQueryParam}/date-schedule/session_schedule/${sessionIdQueryParam}`
        )
        setSessionDetails(data.data)
        console.log(data.data, 'is session details that will appear in popup')
        // Initialize the states based on the fetched data

const formattedStartTime = data.data.start_time.replace(/\s[APap][mM]/, '');
const formattedEndTime = data.data.end_time.replace(/\s[APap][mM]/, '');

// Set the state with the formatted time
setStartTime(formattedStartTime);
setEndTime(formattedEndTime);
        
        setOvernight(data.data.overnight)
        setPrice(data.data.price.total_price)
        setGender(data.data.gender)
      } catch (error: unknown) {
        let errorMessage = 'There was an error fetching the session details. Please try again.'

        if (error instanceof Error) {
          console.error('Error fetching session details:', error.message)
        }

        if (typeof error === 'object' && error !== null && 'response' in error) {
          const err = error as {response: {data: {message: string}}}
          errorMessage = err.response.data.message || errorMessage
        }

        console.error('Error fetching session details:', errorMessage)
        toast.error(errorMessage)
      }
    }

    fetchSessionDetails()
  }, [sessionIdQueryParam, unitQueryParam])

  const handleCreateBooking = () => {
    navigate(
      `/dashboard/schedule/Booking?bookingId=${sessionIdQueryParam}&unitId=${unitQueryParam}`
    )
  }

  const handleCreatePublicEvent = () => {
    navigate(
      `/dashboard/schedule/PublicEvent?publicEventId=${sessionIdQueryParam}&unitId=${unitQueryParam}`
      
    )
  }

  const handleEventUpdate = async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/units/${unitQueryParam}/date-schedule/session_schedule/${sessionIdQueryParam}`,
        {
          start_time: startTime,
          end_time: endTime,
          overnight: overnight,
          _method: 'put',
        }
      )

      toast.success('Session updated successfully')
      // Optionally, you can refetch session details or navigate away
      // navigate(-1)
      if (editModalRef.current) {
        const modal = new window.bootstrap.Modal(editModalRef.current)
        modal.hide()
      }
    } catch (error: unknown) {
      let errorMessage = 'There was an error updating the session. Please try again.'

      if (error instanceof Error) {
        console.error('Error updating session:', error.message)
      }

      if (typeof error === 'object' && error !== null && 'response' in error) {
        const err = error as {response: {data: {message: string}}}
        errorMessage = err.response.data.message || errorMessage
      }

      console.error('Error updating session:', errorMessage)
      toast.error(errorMessage)
    }
  }

  const handleDelete = async () => {
      try {
        // Send a DELETE request to the API
        await axios.delete(
          `${apiUrl}/units/${unitQueryParam}/date-schedule/session_schedule/${sessionIdQueryParam}`
        )

        // If successful, remove the event from the UI

        // Show a success toast notification
        toast.success('Event deleted successfully!')
        navigate(-1);
        // Fetch sessions again to update the data, using the current date
      } catch (error: unknown) {
        let errorMessage = 'There was an error deleting the event. Please try again.'

        // Check if the error is of the type we expect
        if (error instanceof Error) {
          // If the error is an instance of the generic Error class, we can log its message
          console.error('Error deleting event:', error.message)
        }

        // Use type assertion to narrow down the type of error
        if (typeof error === 'object' && error !== null && 'response' in error) {
          const err = error as {response: {data: {message: string}}}
          errorMessage = err.response.data.message || errorMessage
        }

        // Log the error and show an error toast notification
        console.error('Error deleting event:', errorMessage)
        toast.error(errorMessage)
      } finally {
        // Close the context menu
      }
    
  }

  return (
    <>
      <div className='pt-5'>
        <h1>VIEW Session</h1>
        <Toaster />
        <Breadcrumb secondItem=' Page' />
        <div className='row'>
          <div className='col-lg-12'>
            <div className='card p-4 min-h-card'>
              <div className='d-md-flex justify-content-between border-bottom pb-4 pt-2 mb-3 align-items-center'>
                <h4 className='text-lime'>Session Information (# {sessionDetails?.id}) </h4>
                <ul className='d-flex list-unstyled gap-3'>
                  <li>
                    <button className='btn btn-dark p-2 fs-sm' onClick={handleCreateBooking}>
                      <i className='fa-solid fa-plus'></i> Create Booking
                    </button>
                  </li>
                  <li>
                    <button className='btn btn-dark p-2 fs-sm' onClick={handleCreatePublicEvent}>
                      <i className='fa-solid fa-plus'></i> Create Public Event
                    </button>
                  </li>
                  <li>
                    <button
                      className='btn btn-dark p-2 fs-sm'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_2'
                    >
                      <i className='fa-regular fa-pen-to-square'></i> Edit
                    </button>
                  </li>
                  <li>
                    <button className='btn btn-dark p-2 fs-sm' onClick={handleDelete}>
                      <i className='fa-solid fa-trash-can'></i> Delete
                    </button>
                  </li>
                </ul>
              </div>
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Type :</span> <span>{sessionDetails?.type}</span>
              </p>
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Date :</span> <span>{sessionDetails?.date}</span>
              </p>
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Start :</span> <span>{sessionDetails?.start_time}</span>
              </p>
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>End :</span> <span>{sessionDetails?.end_time}</span>
              </p>
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Gender :</span> <span>{sessionDetails?.gender}</span>
              </p>
              <p className='d-flex gap-3 align-items-center'>
                <span className='min_w'>Price :</span>{' '}
                <span>{sessionDetails?.price.total_price} </span>{' '}
                <span>
                  <button className='btn p-0' data-bs-toggle='modal' data-bs-target='#kt_modal_1'>
                    <i className='fa-solid fa-circle-info'></i>
                  </button>
                </span>
              </p>

              {/* modal */}
              <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
                <div className='modal-dialog'>
                  <div className='modal-content'>
                    <div className='modal-header py-2'>
                      <h5 className='modal-title'>Break Down</h5>
                      <div
                        className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                      >
                        x
                      </div>
                    </div>
                    <div className='modal-body mb_no'>
                      <div className='border-bottom pb-3 mb-3'>
                        <div className='mb-3'>
                          <p className='d-flex gap-3 align-items-center'>
                            <span className='min_w'>Base Price :</span>{' '}
                            <span>{sessionDetails?.price.base_price}</span>
                          </p>
                        </div>
                        <div className='mb-3'>
                          <p className='d-flex gap-3 align-items-center'>
                            <span className='min_w'>
                              Discount ({sessionDetails?.price.discount_percent}%) :
                            </span>{' '}
                            <span>{sessionDetails?.price.discount}</span>
                          </p>
                        </div>
                        <div className='mb-'>
                          <p className='d-flex gap-3 align-items-center'>
                            <span className='min_w'>Total Session Price :</span>{' '}
                            <span>{sessionDetails?.price.base_price}</span>
                          </p>
                        </div>
                      </div>
                      <div className='border-bottom pb-3 mb-3'>
                        <p className='d-flex gap-3 align-items-center'>
                          <span className='min_w'>
                            Tax ({sessionDetails?.price.tax_percent}%) :
                          </span>{' '}
                          <span>{sessionDetails?.price.tax}</span>
                        </p>
                      </div>
                    </div>
                    <div className='modal-footer'>
                      <div className='btn btn-light-primary btn-sm' data-bs-dismiss='modal'>
                        Close
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* modal */}
              <div className='modal fade' tabIndex={-1} id='kt_modal_2' data-bs-backdrop='static'>
                <div className='modal-dialog'>
                  <div className='modal-content'>
                    <div className='modal-header py-2'>
                      <h5 className='modal-title'>edit</h5>
                      <div
                        className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                      >
                        x
                      </div>
                    </div>
                    <div className='modal-body'>
                      <div className='row'>
                        <div className='col-12'>
                          <label className='form-label'>Start Time</label>

                          <CustomTimePicker value={startTime} onChange={setStartTime} />
                        </div>
                        <div className='col-12'>
                          <label className='form-label'>End Time</label>

                          <CustomTimePicker value={endTime} onChange={setEndTime} />
                        </div>

                        <small className='text-muted mb-3'>
                          End time must be greater than start time
                        </small>
                        <div className='form-group my-3'>
                          <label className='mb-3'>Overnight</label>
                          <br />
                          <div className='form-check form-check-inline'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='overnight'
                              value='1'
                              checked={overnight == 1}
                              onChange={() => setOvernight(1)}
                            />
                            <label className='form-check-label'>Yes</label>
                          </div>
                          <div className='form-check form-check-inline'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='overnight'
                              value='0'
                              checked={overnight ==0}
                              onChange={() => setOvernight(0)}
                            />
                            <label className='form-check-label'>No</label>
                          </div>
                        </div>
                        <div className='input-group form-control p-0 my-4 align-items-center'>
                          <span className='bg-light btn'>Price</span>
                          <input
                            type='number'
                            className='form-control border-0'
                            value={price}
                            onChange={(e) => setPrice(Number(e.target.value))}
                          />
                          <span className='bg-light btn'>SAR</span>
                        </div>
                        <div className='form-group mb-3'>
                          <label className='mb-3'>Gender</label>
                          <br />
                          <div className='form-check form-check-inline'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='gender'
                              value='male'
                              checked={gender === 'male'}
                              onChange={() => setGender('male')}
                            />
                            <label className='form-check-label'>Male</label>
                          </div>
                          <div className='form-check form-check-inline'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='gender'
                              value='female'
                              checked={gender === 'female'}
                              onChange={() => setGender('female')}
                            />
                            <label className='form-check-label'>Female</label>
                          </div>
                          <div className='form-check form-check-inline'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='gender'
                              value='Both'
                              checked={gender == 'Both'}
                              onChange={() => setGender('Both')}
                            />
                            <label className='form-check-label'>Male & Female</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='modal-footer'>
                      <button
                        type='button'
                        className='btn btn-light-primary btn-sm'
                        data-bs-dismiss='modal'
                      >
                        {intl.formatMessage({id: 'cancel'})}
                      </button>
                      <button
                        type='button'
                        className='btn btn-dark btn-sm'
                        onClick={handleEventUpdate} // Call handleEventUpdate on click
                      >
                        update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Modal */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

// export { Session };
export default Session
